import React, { useEffect, useMemo, useRef } from 'react';

import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { AnalyticsContext } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { fg } from '@atlaskit/platform-feature-flags';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';

import { FeatureExposedAnalytics } from '../analytics/feature-exposed-analytics';
import { useAnalytics } from '../analytics/use-analytics';
import { useEditionAwareness } from '../services/edition-awareness-context';
import { isEligibleForEduDiscount } from '../services/is-eligible-for-edu-discount';
import { isEligibleForStandardToPremium } from '../services/is-eligible-for-standard-to-premium';
import { isEligibleForStandardTrialsUserAndStorageLimitBreakers } from '../services/is-eligible-for-standard-trials-user-and-storage-limit-breakers';
import { useButtonContent } from '../services/use-button-content';
import type { ExperimentCohort, ExperimentCohortControlVariation } from '../types';

import type { actionsAndContent_AtlassianEditionAwareness$key } from './__generated__/actionsAndContent_AtlassianEditionAwareness.graphql';
import type { actionsAndContentConfluenceStorageQuery } from './__generated__/actionsAndContentConfluenceStorageQuery.graphql';
import type { actionsAndContentJiraStorageQuery } from './__generated__/actionsAndContentJiraStorageQuery.graphql';
import { EducationalDiscountDialog } from './dialogs/educational-discount-modal';
import { FreeToStandardDialog } from './dialogs/free-to-standard';
import { StandardToPremiumDialog } from './dialogs/standard-to-premium';
import { ButtonContentContext } from './edition-awareness-button';

/**
 * Sends an event when there is no action available for the customer to render. This is an error state
 * which means that audience and timing has passed a customer as eligible when they shouldn't have.
 *
 * Next iteration on this would be to return a safe default action for all customers
 */
function NoAction() {
	const { sendAnalyticsEvent } = useAnalytics();
	const hasSent = useRef(false);

	useEffect(() => {
		if (hasSent.current) {
			return;
		}

		sendAnalyticsEvent({
			eventType: 'track',
			actionSubject: 'actionsAndContent',
			action: 'missed',
		});

		hasSent.current = true;
	}, [sendAnalyticsEvent]);

	return null;
}

/**
 * Covers what button and action we show to the customer.
 *
 * Experiments here should just modify what is shown to the customer not if it is shown to the customer.
 */
function ActionsAndContentImpl({
	licensedUsers,
	storageUsageInGB,
	trialButton,
	freeButton,
}: {
	licensedUsers?: number | null;
	storageUsageInGB?: number;
	trialButton: React.ReactNode;
	freeButton: React.ReactNode;
}) {
	const context = useEditionAwareness();
	const {
		product,
		edition,
		isInTrial,
		isInPredunning,
		isInvoiceable,
		canAddPaymentDetails,
		hasDismissedButton,
	} = context;

	if (edition === 'free') {
		if (
			isEligibleForEduDiscount(context) &&
			FeatureGates.getExperimentValue<ExperimentCohortControlVariation>(
				'offer_edu_org_discount',
				'cohort',
				'not-enrolled',
			) === 'variation'
		) {
			return (
				<FeatureExposedAnalytics flagKey="offer_edu_org_discount" cohort="variation">
					<EducationalDiscountDialog />
				</FeatureExposedAnalytics>
			);
		}

		// This is control for JPD
		if (product === 'jira-product-discovery' && fg('new_ea_jira_product_discovery_free')) {
			return <FreeToStandardDialog />;
		}

		// Until we migrate the rest of the products to the new button content we'll use the old one from product
		return freeButton;
	}

	if (
		edition === 'standard' &&
		(isInTrial || isInPredunning) &&
		isInvoiceable === false &&
		canAddPaymentDetails
	) {
		if (
			// Only eligible for Confluence and Jira
			isEligibleForStandardTrialsUserAndStorageLimitBreakers({
				context,
				licensedUsers,
				storageUsageInGB,
			})
		) {
			const aaExperimentKey =
				product === 'jira'
					? 'jira_edition_awareness_standard_trials_aa'
					: 'connie_edition_awareness_standard_trials_aa';

			const aaCohort = FeatureGates.getExperimentValue<ExperimentCohort>(
				aaExperimentKey,
				'cohort',
				'not-enrolled',
			);

			return (
				<FeatureExposedAnalytics flagKey={aaExperimentKey} cohort={aaCohort}>
					{trialButton}
				</FeatureExposedAnalytics>
			);
		}
		// trialButton is returned for all other products + Confluence and Jira sites not eligible
		return trialButton;
	}

	if (isEligibleForStandardToPremium(context) && !hasDismissedButton) {
		const aaExperimentKey =
			product === 'confluence'
				? 'edition_awareness_upsell_confluence_aa'
				: 'edition_awareness_upsell_jira_aa';
		const aaCohort = FeatureGates.getExperimentValue<ExperimentCohort>(
			aaExperimentKey,
			'cohort',
			'not-enrolled',
		);

		const abExperimentKey =
			product === 'confluence'
				? 'edition_awareness_standard_upsell_confluence_ab'
				: 'edition_awareness_upsell_jira_ab';
		const abCohort = FeatureGates.getExperimentValue<ExperimentCohort>(
			abExperimentKey,
			'cohort',
			'not-enrolled',
		);

		if (aaCohort === 'treatment' || abCohort === 'treatment') {
			return (
				<>
					{aaCohort === 'treatment' && (
						<FeatureExposedAnalytics flagKey={aaExperimentKey} cohort={aaCohort}>
							<></>
						</FeatureExposedAnalytics>
					)}
					{abCohort === 'treatment' && (
						<FeatureExposedAnalytics flagKey={abExperimentKey} cohort={abCohort}>
							<StandardToPremiumDialog />
						</FeatureExposedAnalytics>
					)}
				</>
			);
		}
	}

	return <NoAction />;
}

/**
 * Responsible for:
 * - Getting data to determine what to show
 * - Handling loading state
 */
export function ActionsAndContent({
	entitlement: entitlementFragment,
	trialButton,
	freeButton,
}: {
	entitlement: actionsAndContent_AtlassianEditionAwareness$key;
	trialButton?: React.ReactNode;
	freeButton?: React.ReactNode;
}) {
	const { cloudId, product, config } = useEditionAwareness();

	/**
	 * When adding data to this query, you should:
	 *
	 * Ensure that it is behind a config that controls the `@include` to ensure that it does
	 *   not query for the data if it is not required.
	 *
	 * That when this data is fetched it will be used the majority of the time (e.g. if this data is only)
	 *   used for 5% of customers and has a high cost then we should likely load it sequentially.
	 */
	const data = useFragment(
		graphql`
			fragment actionsAndContent_AtlassianEditionAwareness on Query {
				tenantContexts(cloudIds: [$cloudId])
					@skip(if: $shouldSkip)
					@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
					entitlementInfo(hamsProductKey: $productKey) {
						entitlement(where: $entitlementFilter) {
							latestUsageForChargeElement(chargeElement: $chargeElement)
								@include(if: $shouldCheckForChargeQuantities)
						}
					}
				}
			}
		`,
		entitlementFragment,
	);

	const jiraStorageData = useLazyLoadQuery<actionsAndContentJiraStorageQuery>(
		graphql`
			query actionsAndContentJiraStorageQuery(
				$cloudId: ID!
				$shouldCheckForStorageUsageJira: Boolean!
				$applicationKey: JiraApplicationKey!
			) {
				jira @include(if: $shouldCheckForStorageUsageJira) {
					attachmentStorageUsed(cloudId: $cloudId, applicationKey: $applicationKey)
				}
			}
		`,
		{
			cloudId,
			shouldCheckForStorageUsageJira: Boolean(
				config.isStandardTrialsUserAndStorageLimitBreakersAAEnabled && product === 'jira',
			),
			applicationKey: 'JIRA_SOFTWARE',
		},
	);

	const confluenceStorageData = useLazyLoadQuery<actionsAndContentConfluenceStorageQuery>(
		graphql`
			query actionsAndContentConfluenceStorageQuery(
				$cloudId: ID!
				$shouldCheckForStorageUsageConfluence: Boolean!
			) {
				confluence_storage(cloudId: $cloudId) @include(if: $shouldCheckForStorageUsageConfluence) {
					bytesUsed
				}
			}
		`,
		{
			cloudId,
			shouldCheckForStorageUsageConfluence: Boolean(
				config.isStandardTrialsUserAndStorageLimitBreakersAAEnabled && product === 'confluence',
			),
		},
	);

	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;

	// We need to use the `latestUsageForChargeElement` to get the licensed user count for both free and trialing customers
	const licensedUsersRaw = entitlement?.latestUsageForChargeElement;
	// Assumes that there are always at least one assigned user to cover scenarios
	const licensedUsers = Math.max(licensedUsersRaw || 0, 1);

	const bytesUsed =
		jiraStorageData?.jira?.attachmentStorageUsed ||
		confluenceStorageData?.confluence_storage?.bytesUsed;
	const storageUsageInGB = bytesUsed && bytesUsed / 1_000_000_000;

	const buttonContent = useButtonContent({ licensedUsers, storageUsageInGB });

	const analyticsContext = useMemo(
		() => ({ licensedUsers, storageUsageInGB }),
		[licensedUsers, storageUsageInGB],
	);

	if (!buttonContent) {
		// We're still loading the content so we'll wait until we know what to show the user
		return <UFOLoadHold name="actions-and-content" />;
	}

	return (
		<AnalyticsContext data={analyticsContext}>
			<ButtonContentContext.Provider value={buttonContent}>
				<ActionsAndContentImpl
					licensedUsers={licensedUsers}
					storageUsageInGB={storageUsageInGB}
					trialButton={trialButton}
					freeButton={freeButton}
				/>
			</ButtonContentContext.Provider>
		</AnalyticsContext>
	);
}
