/**
 * @generated SignedSource<<903bce1f288508fb3a44f578a0d5fd8e>>
 * @relayHash d980b22a4c9289eb0391480664d0b761
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 22bb11cff3c80a352d7644c3f12c7d4f29676e33aec166d7439342d79ff525e4

import type { ConcreteRequest, Query } from 'relay-runtime';
export type dialogEducationalDiscountEditionAwarenessQuery$variables = {
  cloudId: string;
  hamsProductKey: string;
};
export type dialogEducationalDiscountEditionAwarenessQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly experienceCapabilities: {
          readonly changeOfferingV2: {
            readonly experienceUrl: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type dialogEducationalDiscountEditionAwarenessQuery = {
  response: dialogEducationalDiscountEditionAwarenessQuery$data;
  variables: dialogEducationalDiscountEditionAwarenessQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hamsProductKey"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "hamsProductKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "experienceUrl"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "dialogEducationalDiscountEditionAwarenessQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dialogEducationalDiscountEditionAwarenessQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "experienceCapabilities",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "changeOfferingV2",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "22bb11cff3c80a352d7644c3f12c7d4f29676e33aec166d7439342d79ff525e4",
    "metadata": {},
    "name": "dialogEducationalDiscountEditionAwarenessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "499f18c82411a6b52d27e8a71df919ee";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
